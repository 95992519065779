<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <div>
        <p class="text-lg font-weight-semibold mb-2 ">
          Weather Forecast
        </p>
      </div>
    </v-card-title>
    <div class="containter">
        <Search @showCards="showCard = true" @daily="getDaily" @forecast="getForecast" hidden/>
        <Cards :daily="dailyData" :forecast="forecastData" v-if="showCard" />
    </div>
  </v-card>
</template>

<script>
import Cards from '../components/weather/cards.vue'
import Search from '../components/weather/search.vue'
import { isEmpty } from '@core/utils'

export default {
  components: { Cards, Search },
  data() {
    return {
      isEmpty,
      sevenData: [],
      dailyData: [],
      darkMode: false,
      showCard: true,
    }
  },
  methods: {
    // modeChange() {
    //   themeConfig.$emit('dark', this.darkMode)
    // },
    getDaily(data) {
      this.dailyData = data
    },

    getForecast(data) {
      this.forecastData = data
    },
  },
}
</script>
<style>
</style>
