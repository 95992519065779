<template>
  <div class="results">
    <div :style="[{ color: 'black' }]" class="card-1">

      <div class="cards">
        <div class="today">
          <h2>{{ daily.name }}</h2>

          <div class="daily">
            <img
              :src="require(`@/assets/weater_elements/${weathers[daily.weather[0].main]}.svg`)"
              width="120px"
              alt
            />

            <p>{{ parseInt(daily.main.temp) }}°</p>
          </div>

          <!-- <h2>{{ daily.weather[0].main }}</h2> -->
          <!-- <h3>Feels Like {{ parseInt(daily.main.feels_like) }}°</h3> -->
        </div>

        <div class="todayDetails">
          <!-- <h3>
            <img width="64" src="@/assets/weater_elements/humity.png" alt />
            {{ daily.main.humidity }}%
          </h3> -->
          <h3>
            <img src="@/assets/weater_elements/down.png" alt />
            {{ parseInt(daily.main.temp_min) }}°
          </h3>

          <h3>
            <img src="@/assets/weater_elements/up.png" alt />
            {{ parseInt(daily.main.temp_max) }}°
          </h3>
          <!-- <h3>
            <img src="@/assets/weater_elements/wind.png" alt />
            {{ daily.wind.speed }} km/h
          </h3> -->
        </div>
      </div>
    </div>

    <div :style="[ { color: 'black' }]" class="card-2">

      <div class="daysDetail">
        <span
          style="display:flex; flex-direction:column; align-items:center; padding-left:10px"
          v-for="(data,index) in newdata"
          :key="index"
        >
        <p>

          {{dateFormattedddmmm(data.dt_txt.substr(0,10))}} {{data.dt_txt.substr(11,5)}}
        </p>
          <img
            :src="require(`@/assets/weater_elements/${weathers[data.weather[0].main]}.svg`)"
            width="80px"
            alt
          />
          <p>{{ data.weather[0].main }}</p>
          <p>{{ parseInt(data.main.temp_max ) }}° / {{ parseInt(data.main.temp_min ) }}°</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormattedddmmm } from '@core/utils'

export default {
  name: 'Cards',
  props: ['daily', 'forecast'],
  data() {
    return {
      darkMode: false,
      showCard: false,
      weathers: {
        Snow: 'snowy',
        Clouds: 'cloudy',
        Rain: 'rainy',
        Clear: 'sunny',
        Thunderstorm: 'thunder',
      },
      newdata: [],
      dateFormattedddmmm,
    }
  },
  mounted() {
    // const currentDate = new Date()
    // const nextWeek = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000)
    // const days = []
    // while (currentDate <= nextWeek) {
    //   days.push(new Date(currentDate).getDay())
    //   currentDate.setDate(currentDate.getDate() + 1)
    // }
    // this.daysIndex = days.slice(1)
  },
  watch: {
    forecast() {
      let j = 0
      for (let i = 0; i < this.forecast.list.length; i += 1) {
        const date = new Date(this.forecast.list[i].dt_txt)

        if (date > new Date() && j < 7) {
          this.newdata.push(this.forecast.list[i])
          j += 1
        }
      }
    },
  },
}
</script>

<style>
.results {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.cards {
  display: flex;
  width: 100%;
  height: 165px;
  margin-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-1 {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  /* margin-top: 20px; */
  /* padding: 20px; */
}

.today {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.todayDetails {
  display: flex;
  margin-top: 30px;
  margin-left: 30px;
  flex-direction: row;
  align-items: flex-start;
}
.daily {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  flex-wrap: wrap;
  font-size: 90px;
}
.card-2 {
  width: 100%;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  /* margin-top: 20px; */
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
}
.days {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 23px;
}
.daysDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
}

@media (max-width: 1300px) {
  .card-2 {
    align-items: baseline;
  }
  .results {
    width: 90vw !important;
  }
}
</style>
