<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <div>
        <p class="text-lg font-weight-semibold mb-2 ">
          SC Comparison
        </p>
        <span class="text-sm font-weight-semibold me-1"></span><br>
        <span class="text-sm font-weight-semibold me-1"></span><br>
        <span class="text-sm font-weight-semibold me-1"></span>
      </div>
    </v-card-title>
      <div
        ref="barChart"
        :style="{ width: '100%', height: '300px'}"
      ></div>
  </v-card>
</template>

<script>
import { isEmpty } from '@core/utils'
import 'echarts/lib/chart/line'

const echarts = require('echarts')

export default {
  props: {
    userData: {
      type: Object,
      requires: true,
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawBar(val)
      }, 500)
    },
  },
  methods: {
    // bar chart作成する
    drawBar(val) {
      const barChartOption = {
        tooltip: { trigger: 'axis' },
        grid: {
          left: '3%',
          right: '3%',
          top: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            splitNumber: 5,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
            data: val.scLegendData,
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
          },
        ],
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: [
          {
            name: 'Total',
            type: 'bar',
            barWidth: '45%',
            label: {
              normal: {
                show: true,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            data: val.scSeriesData,
          },
        ],
      }

      this.$refs.barChart.style.display = 'block'
      let barChart = echarts.getInstanceByDom(this.$refs.barChart)
      if (barChart == null) {
        barChart = echarts.init(this.$refs.barChart)
      }

      barChart.setOption(barChartOption, true)
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
}
</script>
